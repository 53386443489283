import Vue from 'vue'
import Vuex from 'vuex'
import agency from './modules/agency'
import app from './modules/app'
import caregiver from './modules/caregiver'
import internaladmin from './modules/internaladmin'
import employee from './modules/employee'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    agency,
    app,
    caregiver,
    internaladmin,
    employee,
  },
  actions: {
    RESET_STATE ({ commit }) {
      commit('agency/RESET_STATE')
      commit('app/RESET_STATE')
      commit('caregiver/RESET_STATE')
      commit('internaladmin/RESET_STATE')
      commit('employee/RESET_STATE')
    },
  },
  strict: debug,
})

export default store
