import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/login/Index'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
         {
          name: 'Agencies',
          path: '',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Agencies'),
        },
        {
          name: 'Caregivers',
          path: 'caregivers',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Caregivers'),
        },
        {
          name: 'Employees',
          path: 'employees',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Employees'),
        },
        {
          name: 'Internal admins',
          path: 'iadmins',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/InternalAdmins'),
        },
        {
          name: 'Reports',
          path: 'reports',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Reports'),
        },
        {
          name: 'Settings',
          path: 'settings',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Settings'),
        },
      ],
    },
    // catch all redirect
    {
      path: '*',
      name: 'MyRedirect',
      redirect: { name: 'Agencies' },
    },
  ],
})

const isAuthenticated = () => store.getters['app/IS_AUTHENTICATED']

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router
