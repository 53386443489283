<template>
  <v-btn
    icon
    x-large
    v-on="inputListeners"
  >
    <v-icon>
      mdi-close
    </v-icon>
  </v-btn>
</template>
<script>
  export default {
    name: 'AppCloseIconButton',
    computed: {
      inputListeners: function () {
        var vm = this
        // `Object.assign` merges objects together to form a new object
        return Object.assign(
          {},
          // We add all the listeners from the parent
          this.$listeners,
          // Then we can add custom listeners or override the
          // behavior of some listeners.
          {
            // This ensures that the component works with v-model
            click: function (event) {
              vm.$emit('click', event)
            },
          },
        )
      },
    },
  }
</script>
