const initialState = () => ({
  internalAdmins: [],
  userAgencyRoles: null,
})

const getters = {
  INTERNAL_ADMINS: state => state.internalAdmins,
  USER_AGENCY_ROLES: state => state.userAgencyRoles,
}

const actions = {}

const mutations = {
  RESET_STATE (state, payload) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  SET_INTERNAL_ADMINS (state, payload) {
    state.internalAdmins = payload
  },
  ADD_INTERNAL_ADMIN (state, payload) {
    state.internalAdmins.push(payload)
  },
  SET_USER_AGENCY_ROLES (state, payload) {
    state.userAgencyRoles = payload
  },
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
}
