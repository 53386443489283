const initialState = () => ({
  agencies: [],
})

const getters = {
  AGENCIES: state => state.agencies,
  AGENCY_BY_EXTERNAL_ID: state => externalId => state.agencies.find(el => el.agencyExternalId === externalId),
  AGENCY_BY_UUID: state => uuid => state.agencies.find(el => el.uuid === uuid),
}

const actions = {}

const mutations = {
  RESET_STATE (state, payload) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  ADD_AGENCY (state, payload) {
    state.agencies.push(payload)
  },
  UPDATE_AGENCY (state, payload) {
    const index = state.agencies.map(o => o.id).indexOf(payload.id)
    if (index > -1) {
      state.agencies.splice(index, 1, payload)
    }
  },
  UPDATE_AGENCIES (state, payload) {
    payload.forEach(element => {
      const index = state.agencies.map(o => o.id).indexOf(element.id)
      // set amsType from agencyManagementSystems
      if (element.agencyManagementSystems && element.agencyManagementSystems.length > 0) {
        element.amsType = element.agencyManagementSystems[0].amsType.toUpperCase()
      } else {
        element.amsType = 'None'
      }
      if (index > -1) {
        state.agencies.splice(index, 1, element)
      } else {
        state.agencies.push(element)
      }
    })
  },
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
}
