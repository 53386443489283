const initialState = () => ({
  employees: [],
})

const getters = {
  EMPLOYEES: state => state.employees,
}

const actions = {}

const mutations = {
  RESET_STATE (state, payload) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  SET_EMPLOYEES (state, payload) {
      state.employees = payload
    },
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
}
