export const CCConstants = {
  CAREGIVER: 'caregiver',
  SCHEDULER: 'scheduler',
  ADMIN: 'admin',
  SUSPENDED: 'suspended',
  GRACE: 'grace',
  INVITED: 'invited',
  HRMANAGER: 'hrmanager',
  ELEARN: 'elearn',
  SUPER_ADMIN: 'super_admin',
  CUSTOMER_SUPPORT: 'customer_support',
}

export const domoReportAllowedForSolutionsEnums = [
  'elearning',
  'compliance',
  'shiftbooking',
]

export const userRoleEnums = [
  {
    value: 1,
    text: CCConstants.CAREGIVER,
    color: 'blue',
  },
  {
    value: 2,
    text: CCConstants.SCHEDULER,
    color: 'blue',
  },
  {
    value: 3,
    text: CCConstants.ADMIN,
    color: 'red',
  },
  {
    value: 4,
    text: CCConstants.SUSPENDED,
    color: 'grey',
  },
  {
    value: 7,
    text: CCConstants.HRMANAGER,
    color: 'blue',
  },
  {
    value: 8,
    text: CCConstants.ELEARN,
    color: 'blue',
  },
]

export const portalRoleEnums = [
  {
    value: 2,
    text: CCConstants.SCHEDULER,
    color: 'blue',
  },
  {
    value: 3,
    text: CCConstants.ADMIN,
    color: 'red',
  },
  {
    value: 7,
    text: CCConstants.HRMANAGER,
    color: 'blue',
  },
]

export const domoHelperTexts = {
  name: 'Name: Give your report a name. It is not displayed on CC web portal',
  domoId: 'Domo ID is the Domo dashboard embed ID, use Domo portal to find out correct ID',
  published: 'Only published reports are displayed in CC web portal. You can preview your domo dashboard here before publishing it',
  visibleForRoles: 'User roles that can view this report on CC web portal',
  visibleForSolution: 'Report is displayed in CC web portal if agency has selected solution enabled',
}
