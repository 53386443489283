import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#04A5D5',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
  primaryTeal: '#04A5D5',
  primaryGreen: '#00FF00',
  secondaryNavy: '#364866',
  secondaryOcean: '#25334D',
  secondaryOlive: '#417505',
  cloud: '#F1F1F1',
  aluminium: '#E3E3E3',
  ccsteel: '#C8C8C8',
  ccblueSteel: '#8494AE',
  ccgrey: '#9B9B9B',
  cccharcoal: '#4A4A4A',
  ccred: '#F16778',
  ccorange: '#F5A623',
  ccpink: '#FAD1D6',

}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
