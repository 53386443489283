import store from '../store/index'
import { endSession, startSession } from '../utils/session'

const token = () => `Bearer ${store.getters['app/TOKEN']()}`

const handleErrors = (resp) => {
  if (!resp.ok) {
    return resp.json()
      .catch(() => null)
      .then(json => {
        // 401 status indicates expired authorization token --> user must login again
        if (resp.status === 401) {
          endSession()
        }
        const error = new Error(`HTTP Error ${resp.statusText}`)
        error.status = resp.statusText
        error.statusCode = resp.status
        error.response = resp
        error.json = json
        throw error
      })
  }
  // session updated by server so refresh client side
  startSession()
  return resp
}

const json = (resp) => {
  return resp.json()
}

const fetchWithErrorHandling = (url, init) => {
  return fetch(url, init)
    .then(handleErrors)
    .then(json)
}

export default {
  install (Vue, options) {
    const { mainEndpoint, adminEndpoint } = options

    if (!mainEndpoint || !adminEndpoint) console.error('CareConnect endpoint not defined')

    startSession()

    Vue.prototype.$careConnect = {
      logIn: async (email, password) => {
        return fetchWithErrorHandling(`${mainEndpoint}login`, {
          method: 'POST',
          body: JSON.stringify({
            password,
            username: email,
            agencies: false,
            type: 'webapp',
          }),
        })
      },

      recover: async (email) => {
        return fetchWithErrorHandling(`${mainEndpoint}recover?${new URLSearchParams({ email, type: 'user', source: 'web' })}`, {
          method: 'GET',
        })
      },
      createAgency: async (data) => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies`, {
          method: 'POST',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      getAgency: async (agencyId) => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies/${agencyId}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      getServiceDisciplineMappingFromAMS: async (agencyId) => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies/${agencyId}/ams/sdmapping`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      getAgencies: async () => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      updateAgency: async (agencyId, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies/${agencyId}`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      uploadAgencyLogo: async (uploadUrl, file) => {
        const upload = async event => {
          const response = await fetch(uploadUrl, {
            method: 'PUT',
            body: event.currentTarget.result,
            headers: {
              'Content-Length': file.size,
              'Content-Type': file.type,
            },
          })

          if (response.status >= 300) {
            throw new Error(`HTTP error ${response.statusText}`)
          }

          return response
        }
        const reader = new FileReader()
        reader.onload = upload
        reader.readAsArrayBuffer(file)
      },
      getFileUploadPermissions: async (agencyId, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies/${agencyId}/logo`, {
          method: 'POST',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      updateAgencyLogo: async (agencyId, fileUrl) => {
        return fetchWithErrorHandling(`${adminEndpoint}agencies/${agencyId}/logo`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ fileUrl }),
        })
      },
      getEmployees: async (agencyId) => {
        return fetchWithErrorHandling(`${mainEndpoint}employees?agencyId=${agencyId}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      getEmployee: async (employeeId) => {
        return fetchWithErrorHandling(`${mainEndpoint}employees/${employeeId}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      createEmployee: async (data) => {
        return fetchWithErrorHandling(`${mainEndpoint}employees`, {
          method: 'POST',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      getCaregiver: async (caregiverId) => {
        return fetchWithErrorHandling(`${mainEndpoint}caregivers/${caregiverId}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      updateCaregiver: async (caregiverId, data) => {
        return fetchWithErrorHandling(`${mainEndpoint}caregivers/${caregiverId}`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      getCaregiverByUUID: async (UUID) => {
        return fetchWithErrorHandling(`${adminEndpoint}caregivers/${UUID}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      getCaregiverAgencyProfile: async (id, agencyId) => {
        return fetchWithErrorHandling(`${adminEndpoint}caregivers/${id}/${agencyId}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      getEmployeeAgencyProfile: async (id, agencyId) => {
        return fetchWithErrorHandling(`${adminEndpoint}employees/${id}/${agencyId}`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      searchEmployees: async (name, pageNum) => {
        return fetchWithErrorHandling(`${adminEndpoint}employees?` +
          new URLSearchParams({ name, pageNum }), {
            method: 'GET',
            headers: {
              Authorization: token(),
              'Content-Type': 'application/json',
            },
          })
      },
      updateCaregiverAgencyProfile: async (id, agencyId, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}caregivers/${id}/${agencyId}`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      getInternalAdmins: async () => {
        return fetchWithErrorHandling(`${adminEndpoint}internaladmins`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      createInternalAdmin: async (data) => {
        return fetchWithErrorHandling(`${adminEndpoint}internaladmins`, {
          method: 'POST',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      getUserAgencyRoles: async (id) => {
        return fetchWithErrorHandling(`${adminEndpoint}internaladmins/${id}/roles`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      updateUserAgencyRoles: async (id, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}internaladmins/${id}/roles`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      searchCaregivers: async (name, pageNum) => {
        return fetchWithErrorHandling(`${adminEndpoint}caregivers?` +
          new URLSearchParams({ name, pageNum }), {
            method: 'GET',
            headers: {
              Authorization: token(),
              'Content-Type': 'application/json',
            },
          })
      },
      updateUser: async (id, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}internaladmins/${id}`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      listReports: async (id, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}reports`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      updateReport: async (id, data) => {
        return fetchWithErrorHandling(`${adminEndpoint}reports/${id}`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      deleteReport: async (id) => {
        return fetchWithErrorHandling(`${adminEndpoint}reports/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      createReport: async (data) => {
        return fetchWithErrorHandling(`${adminEndpoint}reports`, {
          method: 'POST',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      previewReport: async (id) => {
        return fetchWithErrorHandling(`${adminEndpoint}reports/${id}/preview`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      getMaintenances: async () => {
        return fetchWithErrorHandling(`${adminEndpoint}maintenance`, {
          method: 'GET',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
      upsertMaintenance: async (data) => {
        return fetchWithErrorHandling(`${adminEndpoint}maintenance`, {
          method: 'PUT',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      },
      removeMaintenance: async (start) => {
        return fetchWithErrorHandling(`${adminEndpoint}maintenance/${start}`, {
          method: 'DELETE',
          headers: {
            Authorization: token(),
            'Content-Type': 'application/json',
          },
        })
      },
    }
  },
}
