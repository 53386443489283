import router from '@/router'
import store from '../store/index'

let idleTimer = null
const sessionTimeout = 10800000

export const startSession = () => {
  clearTimeout(idleTimer)
  idleTimer = setTimeout(() => {
    endSession()
  }, sessionTimeout)
}

export const endSession = () => {
  clearTimeout(idleTimer)
  store.dispatch('RESET_STATE')
  store.commit('app/SET_UNAUTHORIZED_ERROR', 'You have been logged out, please login again.')
  router.replace({ name: 'Login' })
}
