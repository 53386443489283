import { CCConstants } from '@/constants'

const initialState = () => ({
  user: JSON.parse(localStorage.getItem('cc')),
  unauthorizedError: null,
  loading: false,
  drawer: null,
})

const getters = {
  UNAUTHORIZED: state => state.unauthorizedError,
  LOADING: state => state.loading,
  IS_AUTHENTICATED: state => !!state.user,
  IS_ADMIN: state => (state.user && state.user.roles && state.user.roles.includes(CCConstants.SUPER_ADMIN)),
  IS_CUSTOMER_SUPPORT: state => (state.user && state.user.roles && state.user.roles.includes(CCConstants.CUSTOMER_SUPPORT)),
  TOKEN: state => () => state.user && state.user.token,
}

const actions = {}

const mutations = {
  RESET_STATE (state, payload) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
    localStorage.removeItem('cc')
  },
  SET_USER (state, payload) {
    state.user = payload
    localStorage.setItem('cc', JSON.stringify(payload))
  },
  SET_UNAUTHORIZED_ERROR (state, payload) {
    state.unauthorizedError = payload
  },
  SET_LOADING (state, payload) {
    state.loading = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
}
