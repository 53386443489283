<template>
  <v-toolbar flat>
    <slot>
      <v-toolbar-title class="text-h5">
        {{ title }}
      </v-toolbar-title>
    </slot>
    <v-spacer />
    <base-app-close-icon-button @click="close" />
  </v-toolbar>
</template>
<script>
  export default {
    name: 'AppDialogTitle',
    props: {
      title: {
        type: String,
        required: false,
        default: '',
      },
    },
    methods: {
      close () {
        this.$emit('close')
      },
    },
  }
</script>
<style scoped>

</style>
