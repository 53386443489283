<template>
  <v-row>
    <v-col>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="datetime"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datetime"
            :label="title"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-card
          v-if="menu"
          class="ma-0"
        >
          <v-card-text>
            <v-row
              align="center"
              justify="center"
            >
              <v-date-picker
                v-model="date"
                no-title
                :min="minDate"
              />
              <v-time-picker
                v-model="time"
                no-title
                format="24hr"
              />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              :disabled="!date || !time"
              @click="ok"
            >
              OK
            </v-btn>
            <v-btn
              outlined
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  import { format } from 'date-fns'
  export default {
    name: 'DateTimePicker',
    props: {
      title: {
        type: String,
        required: false,
        default: () => 'Datetime',
      },
      value: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        date: this.value ? format(new Date(this.value), 'yyyy-MM-dd') : null,
        time: this.value ? format(new Date(this.value), 'HH:mm') : null,
        minDate: format(new Date(), 'yyyy-MM-dd'),
        menu: false,
        datetime: this.value,
      }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
      ok () {
        const iso = new Date(`${this.date}T${this.time}`).toISOString()
        this.$refs.menu.save(iso)
        this.$emit('input', iso)
      },
      cancel () {
        this.menu = false
      },
    },
  }
</script>
