<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    outlined
    :rules="rules"
    :background-color="color"
    persistent-placeholder
    :name="Math.random()"
    autocomplete="new-password"
    v-on="inputListeners"
  />
</template>

<script>
  export default {
    name: 'MyTextField',
    props: {
      value: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      color () {
        return (
          Object.prototype.hasOwnProperty.call(this.$attrs, 'disabled') &&
          this.$attrs.disabled === true)
          ? 'ccgrey lighten-3' : ''
      },
      inputListeners: function () {
        var vm = this
        // `Object.assign` merges objects together to form a new object
        return Object.assign(
          {},
          // We add all the listeners from the parent
          this.$listeners,
          // Then we can add custom listeners or override the
          // behavior of some listeners.
          {
            // This ensures that the component works with v-model
            input: function (event) {
              vm.$emit('input', event)
            },
          },
        )
      },
    },
    mounted () {
    },
    methods: {
    },
  }
</script>
